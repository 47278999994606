$color-letter-A: #009933;
$color-letter-B: #1ba943;
$color-letter-C: #9ecf1b;
$color-letter-D: #f6f61a;
$color-letter-E: #f4b003;
$color-letter-F: #ff3300;
$color-letter-G:  #ff0000;
$color-bg-gray: #ededed;
$border10px: 10px;
$color-bg-form-homepage-header: #e6e6e6;
$color-bg-form-addition: #d8d8d8;
$color-bg-select-model: #c0c0c0;
$color-bg-label-select: #d5d5d5;
$color-bg-main: #f8f8f8;
$px40: 40px;
$zero: 0;
$color-black: #000;
$red-triangle: #F56D18;
