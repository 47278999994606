@import "vars";
@import "normalize";
@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

body {
  margin: $zero auto;
  font-family: "Open Sans", sans-serif;
}

.form-control:focus {
  box-shadow: none;
}

.footer,
.contact,
.wrapper {
  max-width: 1200px;
  margin: $zero auto;
}

.list-page-wrapper {
  border-bottom: 10px solid white;
  background-color: $color-bg-gray;
  .main {
    background-color: $color-bg-gray;
    &:hover {
      background-color: $color-bg-gray;
    }
  }
}

.footer {
  padding: 15px;
}

.form,
.home-page-header {
  background-color: $color-bg-gray;
}

button:focus {
  outline: none;
}

.navbar-toggler {
  border-radius: 0;
}

.contact {
  margin-bottom: 400px;
  .form .dropdown-toggle::after {
    vertical-align: 0.255em;
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-bottom: 0;
    border-left: 0.25em solid transparent;
    color: $red-triangle;
    cursor: pointer;
    margin: 10px 15px 0 0;
  }
  .dropdown-menu.show {
    width: 100%;
    border-radius: 0;
    margin-top: 0;
    padding: 0;
  }
}

.arrow {
  height: $px40/2;
  background-color: #1e7e34;
  margin-bottom: 3px;
  line-height: 20px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: $zero;
    width: $zero;
    left: 100%;
    top: $zero;
    border: $border10px solid transparent;
  }
}

.footer-letter-A {
  width: 60px;
  background-color: $color-letter-A;
}

.footer-letter-B {
  width: 70px;
  background-color: $color-letter-B;
}

.footer-letter-C {
  width: 80px;
  background-color: $color-letter-C;
}

.footer-letter-D {
  width: 90px;
  background-color: $color-letter-D;
}

.footer-letter-E {
  width: 100px;
  background-color: $color-letter-E;
}

.footer-letter-A:after {
  border-left: $border10px solid $color-letter-A;
}

.footer-letter-B:after {
  border-left: $border10px solid $color-letter-B;
}

.footer-letter-C:after {
  border-left: $border10px solid $color-letter-C;
}

.footer-letter-D:after {
  border-left: $border10px solid $color-letter-D;
}

.footer-letter-E:after {
  border-left: $border10px solid $color-letter-E;
}

.arrow-title-between {
  position: relative;

  &:not(:last-child):after {
    content: ">";
    position: absolute;
    height: $zero;
    width: $zero;
    right: 10px;
    top: 8px;
  }
}

.header-form-homepage {
  background-color: $color-bg-form-homepage-header;
}

.button-form-wrapper {
  padding: 5px;
  .button-form-homepage {
    height: $px40;
  }
}

.label-form-homepage-extended input {
  display: none;
}

.text-form-homepage-extended:before {
  content: "+";
  margin-right: 5px;
}

.label-form-homepage-extended input:checked ~ .text-form-homepage-extended:before {
  content: "-";
  margin-right: 5px;
}

.text-form-homepage-extended:before,
.label-form-homepage-extended input:checked ~ .text-form-homepage-extended:before {
  display: inline-block;
  width: 10px;
}

.label-form-list-extended input {
  display: none;
}

.text-form-list-extended:before {
  content: "\2023";
  cursor: pointer;
  font-size: 2rem;
  margin-right: 5px;
  transition: transform 0.3s ease-in-out;
}

.label-form-list-extended input:checked ~ .text-form-list-extended:before {
  content: "\2023";
  cursor: pointer;
  font-size: 2rem;
  margin-right: 5px;
  transform: rotate(90deg);
}

.category-form {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-check-inline input {
  display: none;
}

.category-letter-A {
  background-color: $color-letter-A;
  border: 2px solid $color-letter-A;
}

.category-letter-B {
  background-color: $color-letter-B;
  border: 2px solid $color-letter-B;
}

.category-letter-C {
  background-color: $color-letter-C;
  border: 2px solid $color-letter-C;
}

.category-letter-D {
  background-color: $color-letter-D;
  border: 2px solid $color-letter-D;
}

.category-letter-E {
  background-color: $color-letter-E;
  border: 2px solid $color-letter-E;
}
.category-letter-F {
  background-color: $color-letter-F;
  border: 2px solid $color-letter-F;
}
.category-letter-G {
  background-color: $color-letter-G;
  border: 2px solid $color-letter-G;
}
.form-check-label input:checked ~ .category-form {
  border: 2px solid $color-black;
}

.form-addition {
  background-color: $color-bg-form-addition;
}

.form-addition-hidden {
  display: none;
}

.disabled {
  background-color: $color-bg-select-model;

  pointer-events: none;
}

.label-select {
  background-color: $color-bg-label-select;
}

.carousel-home-page {
  .carousel-control-prev,
  .carousel-control-next {
    height: $px40/2;
    width: $px40/2;
    margin-left: auto;
    position: absolute;
    top: -30px;
  }
}

.carousel-home-page {
  .carousel-control-prev {
    right: 25px;
  }

  .carousel-control-next {
    right: 5px;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.advertisement-item-list {
  max-width: 728px;
  max-height: 90px;
}

@media (max-width:768px) {
  .img-card-carousel {
    width: calc(50vw - 25px) !important;
  }
  .car-details {
    min-width: 90vw !important;
  }

  .advertisement-item-list,  .advertisement-item-list a img {
    max-width: 90vw!important;
  }
  .advertisement-item-list {
    margin-bottom: 1rem !important;
  }
  .item-car-details {
    font-size: smaller;
  }


  /*smaller header for md*/
  header {
    max-height: 70px;
  }
  header img {
    max-height: 60px;
  }

}

.car-details-title {
  font-size: 1.5rem;
  color: #212529;
}
.img-card-carousel {
  min-width: 100px;
}


.letter-card-Item {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.home-page-addition-info {
  background-color: $color-bg-form-homepage-header;
  height: 250px;
}

.advertising-aside-home-page {
  height: 500px;
}

.img-home-page {
  width: 100%;
}

.car-main .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
}

.main,
.button-modal {
  background-color: #fff;
  &:hover,
  &:active,
  &:focus {
    background-color: #fff;
  }
}

.carousel-detail-pagination .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
  left: 5px;
}

.carousel-detail-pagination .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
  right: 5px;
}

.carousel-detail-pagination .carousel-control-prev-icon,
.carousel-detail-pagination .carousel-control-next-icon {
  position: absolute;
  top: 40%;
}

.carousel-detail-pagination .carousel-control-prev-icon:after {
  border-radius: 0 $px40 * 2 $px40 * 2 0;
  left: -5px;
}

.carousel-detail-pagination .carousel-control-next-icon:after {
  border-radius: $px40 * 2 0 0 $px40 * 2;
  right: -5px;
}

.carousel-detail-pagination .carousel-control-next-icon:after,
.carousel-detail-pagination .carousel-control-prev-icon:after {
  content: "";
  top: -30px;
  position: absolute;
  width: $px40;
  height: $px40 * 2;
  background-color: $color-black;
  z-index: -2;
  opacity: 0.8;
}

.card-header {
  border-bottom: 2px solid #000;
}

.card-body {
  border: none;
  background-color: $color-bg-main;
}

.active:after,
.card .collapse.show:after {
  content: "";
  position: absolute;
  top: $px40/2;
  right: 8px;
  width: $zero;
  height: $zero;
}

.card-header.active:after {
  border-right: 10px solid $color-black;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.collapse.show:after {
  border-top: 10px solid $color-black;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}

.header-form-homepage {
  position: relative;
  top: 0px;
  margin-bottom: 0px;
  border: 7px solid white;


}

.home-page-header-img {
  position: absolute;
  top: 0;
}

.btn:focus,
.btn:active {
  box-shadow: none;
}

.dropdown-toggle {
  border-radius: 0;
}

.dropdown-min-max {
  min-width: 75px;

  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 0;
  }
}

.header-form-homepage .dropdown-min-max .dropdown-toggle,
.header-form-homepage .dropdown-min-max .dropdown-toggle:hover,
.header-form-homepage .dropdown-min-max .dropdown-toggle:focus,
.header-form-homepage .dropdown-min-max .dropdown-toggle:active,
.form .dropdown-min-max .dropdown-toggle,
.form .dropdown-min-max .dropdown-toggle:hover,
.form .dropdown-min-max .dropdown-toggle:focus,
.form .dropdown-min-max .dropdown-toggle:active {
  border-color: $color-bg-label-select;
  min-width: 75px;
  padding-left: 15px;
}

.cursor-pointer {
  cursor: pointer;
  & .tr-table {
    background-color: #fff;
    box-shadow: 2px 16px 23px -25px #000000;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.header-form-homepage {
  .border-form-input {
    border: 1px solid #d5d5d5;
    .dropdown-menu.show {
  box-sizing: content-box;
  left: -1px !important;
  will-change: transform;
  max-height: 300px;
  transform: translate3d(0px, 36px, 0px) !important;
  -webkit-transform: translate3d(0px, 36px, 0px) !important;
  -moz-transform: translate3d(0px, 36px, 0px) !important;
  -ms-transform: translate3d(0px, 36px, 0px) !important;
  -o-transform: translate3d(0px, 36px, 0px) !important;
}
    .dropdown-toggle {
      height: 36px;
      padding-right: 20px;
    }
  }
}

.form-addition {
  label + input,
  label + .row input:not(:last-child) {
    height: 38px;
  }
}

.dropdown-item {
  padding-left: 15px;
  color: gray;
}

.dropdown-item:focus,
.dropdown-item.active,
.header-form-homepage .dropdown-item.active:hover {
  color: #fff;
  text-decoration: none;
  background-color: $color-letter-B;
  padding-left: 15px;
}

.dropdown-item:hover {
  background-color: $color-bg-gray;
  color: $color-black;
}

.header-form-homepage .dropdown-toggle input,
.textarea-contact,
.header-form-homepage .dropdown-toggle input:focus {
  border: none;
  box-shadow: none;
  outline: 0;
  height: 36px;
}

.header-form-homepage .dropdown-toggle::after {
  vertical-align: 0.255em;
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
  color: #757575;
  cursor: pointer;
  margin: 10px 15px 0 0;
}

.form-addition .dropdown-toggle::after {
  color: $red-triangle;
}

.car-main {
  .form {
    .dropdown-toggle {
      border: none;
      &::after {
        content: "◀▶";
        display: inline-block;
        transform: rotate(90deg);
        font-size: 0.6em;
        border: none;
        padding-left: 10px;
      }
    }
    .dropdown-menu.show {
      width: 100%;
      border-radius: 0;
      margin-top: 0;
      padding: 0;
    }
  }
}

//  .dropdown-menu {
//   -webkit-transition: all 0.3s;
//   -moz-transition: all 0.3s;
//   -ms-transition: all 0.3s;
//   -o-transition: all 0.3s;
//   transition: all 0.3s;

//   max-height: 0;
//   display: block;
//   overflow: hidden;
//   opacity: 0;

//   border-top: white;
//   border-radius: 0;
//   width: 100%;
//   max-width: 100%;
//   overflow: scroll;
//   min-width: auto;
//   margin: 0;
//   padding: 0;
// }

.header-form-homepage .dropdown-menu.show {
  border-top: white;
  border-radius: 0;
  width: 100%;
  max-width: 100%;
  overflow: scroll;
  max-height: 300px;
  min-width: auto;
  margin: 0;
  padding: 0;

  //opacity: 1;
}

.checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border: 1px solid gray;
}

/* On mouse-over, add a grey background color */
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a green background */
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: $color-letter-B;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.header-form-homepage .show > .dropdown-toggle {
  border-bottom: 1px solid #fff;
}

.item-dropdown-group {
  min-width: 75px;
}

.list-dropdown {
  .dropdown-menu.show {
    padding: 0;
    border-radius: 0;
  }
}

.text-main-home {
  font-size: 2rem;
}

.background-form-list {
  height: 140px;
  background-color: #f2f2f2;
}

.sort-icon-wrraper {
  width: 20px;
}

.modal-content {
  border-radius: 0;
}

.form-spinner {
  height: 108px;
}

.main-image {
  min-height: 220px;
}

.link {
  cursor: pointer;
}

.list-dropdown .dropdown-toggle::after {
  color: red;
}

.border-form-input input::-webkit-input-placeholder {
  padding-top: 4px;
 }

 .sm-margin {
   margin: 0 5px;
 }

 .sm-margin:first-child {
   margin-left: 0;
 }

.clean-margin {
  margin: 0;
}

.clean-btn {
  background: #434343;
  border: 1px solid #434343;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ("../images/close.png") no-repeat center center / cover;
    width: 15px;
    height: 15px;
  }

  &:hover {
    background: #333;
    border: 1px solid #333;
  }
}

.clean-hidden {
  visibility: hidden;
}

.user-select {
  user-select: none;
}

.list-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  padding: 0;

  li {

    span {
      width: 12px;
      height: 12px;
      color: green;
      margin-right: 5px;
    }
  }
}

.list-advertiser {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
}

@media (min-width: 320px) {
  .list-info {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 400px) {
  .text-main-header {
    display: none;
  }
}

@media (max-width: 450px) {
  .faq {
    h2 {
      font-size: 24px;
    }

    h5,
    p {
      font-size: 14px;
    }
  }
  .dropdown-menu {
    left: -100px;
  }
}

@media (max-width: 575px) {
  .list-dropdown .dropdown-toggle::after {
    color: #ededed;
  }
}

@media (min-width: 850px) {
  .list-info {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .link-active-header:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 8px;
    right: 8px;
    height: 2px;
    background-color: #000;
    border-radius: 5px;
  }
  .carousel-detail-pagination .carousel-inner {
    min-height: 140px;
  }
  .main-image {
    min-height: 280px;
  }
}

.carousel-detail-pagination {
  max-height: 150px;
}
.carousel-item img {
  object-fit: scale-down;
}

@media (max-width: 991px) {
  .link-active-header:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: -20px;
    right: -20px;
    height: 100%;
    background-color: $color-bg-gray;
    z-index: -2;
  }
  .carousel-detail-pagination .carousel-inner {
    min-height: 120px;
  }

}


@media (min-width: 768px) {
  .header-form-homepage {
    top: -70px;
    margin-bottom: -70px;
  }
}

@media (min-width: 991px) {
  .header-form-homepage {
    top: -90px;
    margin-bottom: -90px;
  }
}

@media (min-width: 1100px) {
  .text-main-home {
    padding-left: 30px;
    font-size: 2.3rem;
  }
  .carousel-detail-pagination .carousel-inner {
    min-height: 150px;
  }
  .main-image {
    min-height: 310px;
  }

}


.list-page-wrapper {
  .header-form-homepage {
    top: 0px;
    margin-bottom: 0px
  }
}
@media (min-width: 1200px) {
  .list-page-wrapper {
    border-top: 7px solid white;
  }
  .list-page-wrapper {
    .header-form-homepage {
      top: -70px;
      margin-bottom: -20px
    }
  }
}



@media (min-width: 1400px) {
  .text-main-home {
    padding-left: 0;
    font-size: 3rem;
  }
  .carousel-detail-pagination .carousel-inner {
    min-height: 155px;
  }
  .main-image {
    min-height: 400px;
  }
}

@media (min-width: 1600px) {
  .main-image {
    min-height: 450px;
  }
  .header-form-homepage {
    top: -130px;
    margin-bottom: -130px
  }
}

@media (min-width: 1800px) {
  .text-main-home {
    font-size: 3.3rem;
  }
  .main-image {
    min-height: 500px;
  }



}

@media (min-width: 2000px) {
  .main-image {
    min-height: 560px;
  }
  .header-form-homepage {
    top: -180px;
    margin-bottom: -180px
  }
}


